/* src/assets/styles/About.scss */

.About-Page {
    text-align: center;
    padding-top: 50px; 
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 120px;
    height: auto;

    .About-Title0 {
        font-size: 64px;
        color: #ffffff;
        margin-top: 50px;
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0;
    }

    .About-Title1 {
        font-size: 48px;
        color: var(--color-primary);
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0.5;
    }

    .About-Title2 {
        font-size: 32px; 
        color: var(--color-white);
        background-color: var(--color-background);
        padding: 16px;
        box-shadow: 3px 6px 12px var(--color-primary);
        border-radius: 8px;
        margin-bottom: 32px;
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0.5;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        transition: transform 0.5s ease, background-color 0.5s ease, box-shadow 0.5s ease;
    }

    .About-Container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(600px, max-content));
        justify-content: center;
    }

    .About-Card {
        background: var(--color-background);
        border-radius: 24px;
        padding: 24px;
        text-align: left;
        box-shadow: 3px 6px 12px var(--color-primary);
        transition: transform 0.3s ease;
        cursor: pointer;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px;
        margin-left: 48px;
        margin-right: 48px;
    }

    .About-Name {
        font-size: 24px;
        margin-bottom: 16px; 
        color: var(--color-primary);
        font-family: 'Lovela';
    }

    .About-Detail {
        font-size: 17.6px;
        color: #ffffff;
        margin: 8px 0;
        font-weight: bold;
        text-align: center;
    }
}

@media (max-width:1440px) {

    .About-Title0 {
        font-size: 55px !important;
    }

    .About-Title1 {
        font-size: 40px !important;
    }

    .About-Title2 {
        font-size: 30px !important;
    }

    .About-Card {
        padding: 20px;
    }

    .About-Name {
        font-size: 20x !important;
    }

    .About-Detail {
        font-size: 18px !important;
    }

}

@media (max-width: 600px) {

    .About-Card {
        max-width: 250px !important;
    }

    .About-Container {
        justify-items: center;
    }

    .About-Title0 {
        font-size: 48px !important;
    }

    .About-Title1 {
        font-size: 32px !important;
    }

    .About-Title2 {
        font-size: 27.2px !important;
    }

    .About-Card {
        padding: 12.8px;
    }

    .About-Name {
        font-size: 19.2px !important;
    }

    .About-Detail {
        font-size: 12.8px !important;
    }
}

@media (max-width: 480px) {

    .About-Title0 {
        font-size: 40px;
    }

    .About-Title1 {
        font-size: 24px;
    }

    .About-Title2 {
        font-size: 16px;
    }
}

@media (max-width: 325px) {

    .About-Card {
        max-width: 200px !important;
    }

    .About-Title0 {
        font-size: 30px;
    }

    .About-Title1 {
        font-size: 18px;
    }

    .About-Title2 {
        font-size: 10px;
    }


    .About-Card {
        padding: 8px
    }

    .About-Name {
        font-size: 16px !important;
    }

    .About-Detail {
        font-size: 12px !important;
    }
}