//src/assests/styles/Footer.cscss

.Footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background);
    color: #ffffff;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.social-media-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    a {
        color: #ffffff;
        font-size: 2.5rem;
        margin: 0 20px; 
        transition: color 0.3s ease;

        &:hover {
            color: var(--color-primary); 
        }
    }
}

.copyright {
    display: flex;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 10px;
    text-align: center; 
}

/* Media Query for smaller devices */
@media (max-width: 768px) {
    .social-media-icons {
        a {
            font-size: 2rem; 
            margin: 0 15px; 
        }
    }

    .copyright {
        font-size: 0.875rem; 
    }
}

@media (max-width: 480px) {
    .social-media-icons {
        a {
            font-size: 1.5rem; 
            margin: 0 10px; 
        }
    }

    .copyright {
        font-size: 0.75rem; 
    }
}
