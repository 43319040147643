/* src/assets/styles/Blogs.scss */

.Blogs-Page {
    text-align: center;
    padding: 50px;
    /* Converted from 2rem */
    height: 700px;
    /* Converted from 78vh */

    .Blogs-Title0 {
        font-size: 64px;
        /* Converted from 4rem */
        color: #ffffff;
        margin-top: 50px;
        /* Already in px */
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0;
    }

    .Blogs-Title1 {
        font-size: 48px;
        /* Converted from 3rem */
        color: var(--color-primary);
        margin-bottom: 32px;
        /* Converted from 2rem */
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0.5;
    }

    .Blogs-Container {
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
        // justify-content: center;
        //max-width: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
        justify-content: center;
    }

    .Blogs-Card {
        background: var(--color-background);
        border-radius: 15px;
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
        text-align: left;
        box-shadow: 2px 3px 6px var(--color-primary);
        transition: transform 0.3s ease;
        cursor: pointer;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px; /* Converted from 3rem */
        margin-left: 48px; /* Converted from 3rem */
        margin-right: 48px; /* Converted from 3rem */

        &:hover {
            transform: translateY(-10px);
        }

        .Blogs-Image {
            width: 300px;
            height: 150px;
            border-radius: 10px; /* Small rounding for corners */
            margin-bottom: 16px; /* Converted from 1rem */
            object-fit: cover;
            box-shadow: 0.5px 2px 5px 0px var(--color-primary); /* Optional: add shadow */
        }

        .Blogs-Info {
            h2 {
                font-size: 19.2px;
                /* Converted from 1.2rem */
                margin-bottom: 16px;
                /* Converted from 1rem */
                color: var(--color-primary);
                font-family: 'Lovela';
            }

            h3 {
                margin-top: 8px;
                /* Converted from 5px */
                font-size: 16px;
                /* Converted from 1rem */
                font-weight: normal;
                color: #ffffff;
            }
        }

        .Blog-Buttons {
            display: flex;
            align-items: center;

            .blog-insta-icon {
                color: #ffffff;
                font-size: 2rem;
                transition: color 0.3s ease;
                margin-left: 10px;
                margin-top: 5px;

                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}

/* Media Queries */
@media (max-width: 1440px) {
    .Blogs-Page {
        height: 800px;
    }
}

/* For screens with 1024px width */
@media (max-width: 1024px) {
    .Blogs-Page {
        height: 1200px;
    }

    .Blogs-Card {
        padding: 16px;
        /* Converted from 1rem */

        .Blogs-Info {
            h2 {
                font-size: 18px;
                /* Converted from 1rem */
            }

            h3 {
                font-size: 15px;
                /* Converted from 0.9rem */
            }
        }
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {

    .Blogs-Page {
        height: auto !important;
    }

    .Blogs-Title0 {
        font-size: 48px !important;
        /* Converted from 3rem */
    }

    .Blogs-Title1 {
        font-size: 32px !important;
        /* Converted from 2rem */
    }

    .Blogs-Card {
        padding: 14.8px !important;
        /* Converted from 0.8rem */
        margin-top: 20px !important;
        /* Converted from 3rem */
        margin-left: 20px !important;
        /* Converted from 3rem */
        margin-right: 20px !important;
        /* Converted from 3rem */

        .Blogs-Image {
            width: 200px !important;
            height: 100px !important;
        }

        .Blogs-Info {
            h2 {
                font-size: 16px !important;
                /* Converted from 0.9rem */
            }

            h3 {
                font-size: 14px !important;
                /* Converted from 0.8rem */
            }
        }

        .blog-insta-icon {
            font-size: 1.7rem !important;
            margin-left: 2px !important;
        }
    }
}