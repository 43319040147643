.series-section {
    margin: 20px auto;
    text-align: center;
    margin-bottom: 100px;
}

.section-title {
    font-size: 50px;
    font-family: ZuumeRough;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center;
    /* Text color */
}

.series-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping to the next line for smaller screens */
    justify-content: center;
    /* Centers the items horizontally */
    gap: 40px;
    /* Adds spacing between cards */
}

.series-card {
    display: flex;
    flex-direction: column;
    /* Stacks image, title, and other content vertically */
    align-items: center;
    /* Centers content inside the card */
    background-color: #121728;
    /* Background for cards */
    border-radius: 10px;
    box-shadow: 0.5px 2px 5px 0px var(--color-primary);
    padding: 20px;
    width: 300px;
    /* Fixed width for cards */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.05);
        /* Optional: Add a hover shadow effect */
    }
}

.series-image {
    width: 300px;
    height: auto;
    border-radius: 10px;
    /* Small rounding for corners */
    margin-bottom: 16px;
    /* Converted from 1rem */
    object-fit: cover;
    box-shadow: 0.5px 2px 5px 0px var(--color-primary);
    /* Optional: add shadow */
}

.series-title {
    //text-decoration: none;
    font-size: 19.2px;
    margin-bottom: 15px;
    color: #7CF3FF;
    /* Title color */
    font-family: 'Lovela';
}

.series-link {
    text-decoration: none;
}

.episode-link {
    text-decoration: none;
    color: #FFFFFF;
    /* Default link color */
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    display: inline-block;
    transition: color 0.3s ease, text-shadow 0.3s ease;

    &:hover {
        color: #121728;
        //text-shadow: 0 0 5px #7CF3FF, 0 0 10px #FFFFFF; /* Glow effect on hover */
    }
}

/* Dropdown Button */
.dropdown-button {
    background-color: #ffffff;
    color: #121728;
    font-size: 26px;
    padding: 5px 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #7CF3FF;
        transform: scale(1.05);
    }
}


/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it appears above all content */
}

/* Modal Content */
.modal-content {
    background-color: #121728;
    /* Match the card background */
    border-radius: 15px;
    padding: 30px 0px;
    width: 90%;
    max-width: 700px;
    /* Centered and responsive */
    text-align: center;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.4s ease;
    /* Smooth appearance animation */
    position: relative;
    /* For layout consistency */
}

/* Modal Header (Image + Title) */
.modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.modal-image {
    width: 80%;
    //max-width: 300px; /* Adjust size as needed */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0.5px 2px 5px 0px var(--color-primary);
    margin-bottom: 16px;
}

.modal-title {
    font-size: 20px;
    color: #7CF3FF;
    font-family: "Lovela";
}

/* Episode List (Scrollable) */
.modal-episode-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    /* Allow the list to grow and take available space */
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 20vh;
    /* Limit the height of the episode list */

    li {
        margin: 10px 0;

        .episode-link {
            text-decoration: none;
            font-size: 18px;
            color: #ffffff;
            font-weight: bold;
            transition: color 0.3s ease;

            &:hover {
                color: #7CF3FF;
            }
        }
    }

    /* Add scrollbar styles for better aesthetics */
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        /* Scrollbar color */
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: #121728;
        /* Scrollbar track color */
    }
}

/* Close Button */
.close-modal-button {
    background-color: #7cf3ff;
    color: #121728;
    font-size: 22px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ffffff;
    }
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .series-image {
        width: 250px !important;
    }

    .modal-content {
        max-width: 300px !important;
        padding: 30px 20px !important;
    }

    .modal-image {
        width: 100% !important;
        height: auto !important;
    }
}