// src/components/WritersProfiles/WritersProfiles.scss

.abdullahsattar-writer-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    text-align: center;
    padding: 32px; /* Converted from 2rem */
    height: auto; /* Converted from 80vh */

    img {
        margin-top: 50px; /* Already in px */
        width: 150px; /* Already in px */
        height: 150px; /* Already in px */
        border-radius: 50%;
        box-shadow: 0.5px 2px 5px 0px var(--color-primary);
    }

    h1 {
        font-size: 48px; /* Converted from 3rem */
        margin-bottom: 0px; /* Converted from 0rem */
        font-family: 'ZuumeRough';
        color: #ffffff;
    }

    .abdullahsattar-Writer-Intro {
        margin-bottom: 10px; /* Already in px */
        text-align: center;

        h2 {
            line-height: 0.5;
            font-size: 24px; /* Converted from large */
        }
    }

    .abdullahsattar-Publish {
        color: var(--color-primary);
        line-height: 1.5;
        font-weight: bold;
        font-size: 24px; /* Converted from large */
    }

    .abdullahsattar-Writer-About {
        font-size: 20.8px; /* Converted from 1.3rem */
        margin-top: 30px; /* Already in px */
        color: #ffffff;
        font-family: Ubuntu;
        font-weight: 500;
        max-width: 85%; /* Already in percentage */
        text-align: justify;
        margin-bottom: 20px; /* Already in px */
    }

    .abdullahsattar-Writer-social-media-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px; /* Already in px */
        margin-bottom: 5px; /* Already in px */

        a {
            color: #ffffff;
            font-size: 24px; /* Converted from 1.5rem */
            margin: 0 5px; /* Already in px */
            transition: color 0.3s ease;

            &:hover {
                color: var(--color-primary);
            }
        }
    }

    .abdullahsattar-Blogs-Container {
        max-width: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    
    .abdullahsattar-Blogs-Card {
        background: var(--color-background);
        border-radius: 15px;
        padding-top: 0px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
        text-align: left;
        box-shadow: 2px 3px 6px var(--color-primary);
        transition: transform 0.3s ease;
        cursor: pointer;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px; /* Converted from 3rem */
        margin-left: 48px; /* Converted from 3rem */
        margin-right: 48px; /* Converted from 3rem */
    
        &:hover {
            transform: translateY(-10px);
        }
    
        .abdullahsattar-Blogs-Image {
            width: 300px;
            height: 150px;
            border-radius: 10px; /* Small rounding for corners */
            margin-bottom: 16px; /* Converted from 1rem */
            object-fit: cover;
            box-shadow: 0.5px 2px 5px 0px var(--color-primary); /* Optional: add shadow */
        }        
    
        .abdullahsattar-Blogs-Info {
            h2 {
                font-size: 19.2px; /* Converted from 1.2rem */
                margin-bottom: 16px; /* Converted from 1rem */
                color: var(--color-primary);
                font-family: 'Lovela';
            }
    
            h3 {
                margin-top: 8px; /* Converted from 5px */
                font-size: 16px; /* Converted from 1rem */
                font-weight: normal;
                color: #ffffff;
            }
        }
    
        .abdullahsattar-Blog-Buttons {
            display: flex;
            align-items: center;
    
            .abdullahsattar-blog-insta-icon {
                color: #ffffff;
                font-size: 2rem;
                transition: color 0.3s ease;
                margin-left: 10px;
                margin-top: 5px;
    
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}

/* Media Queries */
/* For screens with 1024px width */
@media (max-width: 1024px) {
    .abdullahsattar-writer-profile {

        h1 {
            font-size: 40px; /* Converted from 2.5rem */
        }
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {

    img {
        width: 120px !important;
        height: 120px !important;
    }

    .abdullahsattar-Writer-About {
        max-width: 90% !important;
        font-size: 16px !important; /* Converted from 1rem */
    }
    .abdullahsattar-Writer-Intro {
        h2 {
            line-height: 1 !important;
            font-size: 18px !important;
        }
    }
}

@media (max-width: 1024px) {
    .abdullahsattar-Blogs-Card {
        padding-top: 0px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        padding-bottom: 16px !important;

        .Blogs-Info {
            h2 {
                font-size: 18px !important; /* Converted from 1rem */
            }

            h3 {
                font-size: 15px !important; /* Converted from 0.9rem */
            }
        }
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {

    .abdullahsattar-Blogs-Card {
        padding-top: 0px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        padding-bottom: 12px !important;
        margin-top: 20px !important; /* Converted from 3rem */
        margin-left: 20px !important; /* Converted from 3rem */
        margin-right: 20px !important; /* Converted from 3rem */

        .abdullahsattar-Blogs-Image {
            width: 200px !important;
            height: 100px !important;
        }

        .abdullahsattar-Blogs-Info {
            h2 {
                font-size: 16px !important; /* Converted from 0.9rem */
            }

            h3 {
                font-size: 14px !important; /* Converted from 0.8rem */
            }
        }

        .abdullahsattar-blog-insta-icon {
            font-size: 1.7rem !important;
            margin-left: 2px !important;
        }
    }
}