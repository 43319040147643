// src/components/WritersProfiles/WritersProfiles.scss

.writer-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    text-align: center;
    padding: 32px; /* Converted from 2rem */
    height: 800px; /* Converted from 80vh */

    img {
        margin-top: 50px; /* Already in px */
        width: 150px; /* Already in px */
        height: 150px; /* Already in px */
        border-radius: 50%;
        box-shadow: 0.5px 2px 5px 0px var(--color-primary);
    }

    h1 {
        font-size: 48px; /* Converted from 3rem */
        margin-bottom: 0px; /* Converted from 0rem */
        font-family: 'ZuumeRough';
        color: #ffffff;
    }

    .Writer-Intro {
        margin-bottom: 10px; /* Already in px */
        text-align: center;

        h2 {
            line-height: 0.5;
            font-size: 24px; /* Converted from large */
        }
    }

    .Publish {
        color: var(--color-primary);
        line-height: 1.5;
        font-weight: bold;
        font-size: 24px; /* Converted from large */
    }

    .Writer-About {
        font-size: 20.8px; /* Converted from 1.3rem */
        margin-top: 30px; /* Already in px */
        color: #ffffff;
        font-family: Ubuntu;
        font-weight: 500;
        max-width: 85%; /* Already in percentage */
        text-align: justify;
        margin-bottom: 20px; /* Already in px */
    }

    .Writer-social-media-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px; /* Already in px */
        margin-bottom: 5px; /* Already in px */

        a {
            color: #ffffff;
            font-size: 24px; /* Converted from 1.5rem */
            margin: 0 5px; /* Already in px */
            transition: color 0.3s ease;

            &:hover {
                color: var(--color-primary);
            }
        }
    }
}

/* Media Queries */

/* For screens with 1440px width */
@media (max-width: 1440px) {
    .writer-profile {
        height: 850px;
    }
}

/* For screens with 1024px width */
@media (max-width: 1024px) {
    .writer-profile {
        height: 1200px; /* Converted from 85vh */

        h1 {
            font-size: 40px; /* Converted from 2.5rem */
        }
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {
    .writer-profile {
        height: 850px;
    }

    img {
        width: 120px !important;
        height: 120px !important;
    }

    .Writer-About {
        max-width: 90% !important;
        font-size: 16px !important; /* Converted from 1rem */
    }

    .Writer-Intro {
        h2 {
            line-height: 1 !important;
            font-size: 18px !important;
        }
    }
}

@media (max-width: 600px) {
    .writer-profile {
        height: auto;
    } 
    
}

@media (max-width: 380px) {
    .Writer-Intro {
        h2 {
            font-size: 18px !important; /* Converted from medium */
        }
    }
}
