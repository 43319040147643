/* src/assets/styles/Writers.scss */

.writers-page {
    text-align: center;
    padding: 50px; /* Converted from 2rem */
    height: 700px; /* Converted from 78vh */

    .writers-title0 {
        font-size: 64px; /* Converted from 4rem */
        color: #ffffff;
        margin-top: 50px; /* Already in px */
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0;
    }

    .writers-title1 {
        font-size: 48px; /* Converted from 3rem */
        color: var(--color-primary);
        margin-bottom: 32px; /* Converted from 2rem */
        font-weight: 50;
        font-family: 'ZuumeRough';
        line-height: 0.5;
    }

    .writers-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
        justify-content: center;
    }

    .writer-card {
        background: var(--color-background);
        border-radius: 15px;
        padding: 24px; /* Converted from 1.5rem */
        text-align: left;
        box-shadow: 2px 3px 6px var(--color-primary);
        transition: transform 0.3s ease;
        cursor: pointer;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px; /* Converted from 3rem */
        margin-left: 48px; /* Converted from 3rem */
        margin-right: 48px; /* Converted from 3rem */

        &:hover {
            transform: translateY(-10px);
        }

        .writer-image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 16px; /* Converted from 1rem */
            object-fit: cover;
            box-shadow: 0.5px 2px 5px 0px var(--color-primary);
        }

        .writer-info {
            h2 {
                font-size: 19.2px; /* Converted from 1.2rem */
                margin-bottom: 16px; /* Converted from 1rem */
                color: var(--color-primary);
                font-family: 'Lovela';

                &:hover {
                    color: #ffffff;
                }
            }

            h3 {
                margin-top: 8px; /* Converted from 5px */
                font-size: 16px; /* Converted from 1rem */
                font-weight: normal;
                color: #6c757d;
            }

            p {
                font-size: 16px; /* Converted from 1rem */
                color: #ffffff;
                margin: 8px 0; /* Converted from 0.5rem */

                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}

/* Media Queries */
@media (max-width: 1440px) {
    .writers-page {
        height: 800px;
    }
}

/* For screens with 1024px width */
@media (max-width: 1024px) {
    .writers-page {
        height: 1200px;
    }

    .writer-card {
        padding: 16px; /* Converted from 1rem */

        .writer-info {
            h2 {
                font-size: 16px; /* Converted from 1rem */
            }

            p {
                font-size: 14.4px; /* Converted from 0.9rem */
            }
        }
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {

    .writers-title0 {
        font-size: 48px !important; /* Converted from 3rem */
    }

    .writers-title1 {
        font-size: 32px !important; /* Converted from 2rem */
    }

    .writer-card {
        padding: 12.8px; /* Converted from 0.8rem */

        .writer-image {
            width: 70px !important;
            height: 70px !important;
        }

        .writer-info {
            h2 {
                font-size: 14.4px; /* Converted from 0.9rem */
            }

            p {
                font-size: 12.8px; /* Converted from 0.8rem */
            }
        }
    }
}

@media (max-width: 600px) {
    .writers-page {
        height: auto;
    }
}
