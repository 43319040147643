/* src/assets/styles/Header.scss */

@font-face {
    font-family: 'ZuumeRough';
    src: url('../fonts/Zuume\ Rough/ZuumeRough-Bold.ttf') format('opentype');
}

.header {
    flex: 1;
    text-align: center;
    color: #ffffff;
    padding-right: 130px;
    padding-top: 60px;
    box-sizing: border-box;

    h1 {
        font-family: 'ZuumeRough';
        font-size: 96px; /* Converted from 6rem */
        font-weight: 50; /* Ensure the font is bold */
        line-height: 0; /* Reduced line spacing */
    }

    h2 {
        font-family: 'ZuumeRough';
        font-weight: 100;
        font-size: 48px; /* Converted from 3rem */
        color: #7CF3FF;
        line-height: 0; /* Reduced line spacing */
    }

    .quote {
        p {
            font-family: 'ZuumeRough';
            font-size: 24px; /* Converted from 1.5rem */
            font-weight: normal; /* Adjust font weight as needed */
            margin-bottom: 5px; /* Reduced space between quote and reference */
            line-height: 1.1; /* Reduced line spacing */
        }

        .reference {
            font-family: 'ZuumeRough';
            font-size: 24px; /* Converted from 1.5rem */
            font-weight: normal; /* Adjust font weight as needed */
            line-height: 0; /* Reduced line spacing */
        }
    }
}

@media (max-width: 1024px) {
    .header {
        max-width: 100%;
        align-items: center;
        padding: 10px;
    }
}

@media (max-width: 600px) {
    .header {
        padding-top: 16px; /* Converted from 10px */
        
        h1 {
            font-size: 64px; /* Converted from 4rem */
        }

        h2 {
            font-size: 32px; /* Converted from 2rem */
        }

        .quote {
        
            p {
                font-size: 19.2px; /* Converted from 1.2rem */
            }
            
            .reference {
                font-size: 16px; /* Converted from 1rem */
            }
        }
    }
}
