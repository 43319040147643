.home-page {
    height: auto; /* Adjust height to allow for vertical scrolling */
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    margin-top: 100px;
    margin-bottom: 200px;
  }
  
  /* Neon line divider */
  .neon-line {
    width: 90%;
    height: 4px;
    background: linear-gradient(90deg, #7CF3FF, #FFFFFF, #7CF3FF);
    box-shadow: 0 0 10px #7CF3FF, 0 0 20px #FFFFFF, 0 0 30px #121728;
    margin: 20px 0;
    border-radius: 10px;
  }
  

  /* Responsive adjustments for specific screen sizes */

/* For screens with 1440px width */
@media (max-width: 1440px) {
    .content-wrapper {
        max-width: 90%; /* Adjust content wrapper width for 1440px */
    }
}

/* For screens with 1260px width */
@media (max-width: 1260px) {
    .content-wrapper {
        margin-bottom: 30px;
        margin-top: 30px;
    }
}