/* src/assets/styles/Videos.scss */

.Videos-Page {
    text-align: center;
    padding: 50px;
    /* Converted from 2rem */
    min-height: 900px;
    /* Converted from 78vh */
}

.Videos-Title0 {
    font-size: 64px;
    /* Converted from 4rem */
    color: #ffffff;
    margin-top: 50px;
    /* Already in px */
    font-weight: 50;
    font-family: 'ZuumeRough';
    line-height: 0;
}

.Videos-Title1 {
    font-size: 48px;
    /* Converted from 3rem */
    color: var(--color-primary);
    margin-bottom: 32px;
    /* Converted from 2rem */
    font-weight: 50;
    font-family: 'ZuumeRough';
    line-height: 0.5;
}

.Videos-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    justify-content: center;
    margin-top: 100px;
    gap: 80px;
    //max-width: 90%;
}

/* For each video item */
.video-item {
    width: 100%;
    max-width: 650px; /* Slightly wider for large screens */
    position: relative;
    aspect-ratio: 9/16; /*(height = width * 16 / 9) */
    box-shadow: 2px 3px 6px var(--color-primary);
    border-radius: 10px;
}

.video-item iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

/* For screens with 1024px width */
@media (max-width: 1024px) {
    .Videos-Page {
        height: 1200px;
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {
    .Videos-Page {
        height: auto !important;
    }

    .Videos-Title0 {
        font-size: 48px !important;
    }

    .Videos-Title1 {
        font-size: 32px !important;
    }
}
