/* src/assets/styles/Duas.scss */

.Duas-Page {
    text-align: center;
    padding: 50px;
    /* Converted from 2rem */
    min-height: 800px;
    /* Converted from 78vh */
}

.Duas-Title0 {
    font-size: 64px;
    /* Converted from 4rem */
    color: #ffffff;
    margin-top: 50px;
    /* Already in px */
    font-weight: 50;
    font-family: 'ZuumeRough';
    line-height: 0;
}

.Duas-Title1 {
    font-size: 48px;
    /* Converted from 3rem */
    color: var(--color-primary);
    margin-bottom: 32px;
    /* Converted from 2rem */
    font-weight: 50;
    font-family: 'ZuumeRough';
    line-height: 0.5;
}

.Duas-Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    justify-content: center;
}

.Duas-Card {
    //background: var(--color-background);
    border-radius: 15px;
    // padding-top: 20px;
    // padding-left: 24px;
    // padding-right: 24px;
    // padding-bottom: 20px;
    text-align: left;
    //box-shadow: 2px 3px 6px var(--color-primary);
    transition: transform 0.3s ease;
    cursor: pointer;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px; /* Converted from 3rem */
    margin-left: 48px; /* Converted from 3rem */
    margin-right: 48px; /* Converted from 3rem */

    &:hover {
        transform: translateY(-10px);
    }

    // .Duas-Info {
    //     h2 {
    //         font-size: 19.2px; /* Converted from 1.2rem */
    //         margin-bottom: 16px; /* Converted from 1rem */
    //         color: var(--color-primary);
    //         font-family: 'Lovela';
    
    //         &:hover {
    //             color: #ffffff;
    //         }
    //     }
    // }

    .Duas-Image {
        width: 300px;
        height: 130px;
        border-radius: 10px; /* Small rounding for corners */
        //margin-bottom: 16px; /* Converted from 1rem */
        object-fit: cover;
        box-shadow: 0.5px 2px 5px 0px var(--color-primary); /* Optional: add shadow */
    }
}


@media (max-width: 1440px) {
    .Duas-Page {
        height: 800px;
    }
}

/* For screens with 1024px width */
@media (max-width: 1024px) {
    .Duas-Page {
        height: 1200px;
    }
}

/* For screens with 768px width */
@media (max-width: 768px) {

    .Duas-Page {
        height: auto !important;
    }

    .Duas-Title0 {
        font-size: 48px !important;
        /* Converted from 3rem */
    }

    .Duas-Title1 {
        font-size: 32px !important;
        /* Converted from 2rem */
    }

    .Duas-Card {
        /* Converted from 0.8rem */
        margin-top: 20px !important;
        /* Converted from 3rem */
        margin-left: 20px !important;
        /* Converted from 3rem */
        margin-right: 20px !important;
        /* Converted from 3rem */

        .Duas-Image {
            width: 200px !important;
            height: 90px !important;
        }
    }
}