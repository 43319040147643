/* src/assets/styles/BackButton.scss */
.back-button {
    background-color: var(--color-primary);
    color: var(--color-background);
    border: none;
    padding: 7px 10px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 0;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 40px;
    left: 50px;
}

.back-button:hover {
    background-color: #ffffff
}

@media (max-width: 768px) {

    .back-button {
        font-size: 15px;
    }
}

@media (max-width: 600px) {
    .back-button {
        top: 10px;
        left: 20px;
    }

}