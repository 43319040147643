// src/App.scss

@font-face {
    font-family: 'Lovela';
    src: url('./assests/fonts/Lovelo/Lovelo\ Black.otf') format('opentype');
}

@font-face {
    font-family: 'ZuumeRough';
    src: url('./assests/fonts/Zuume\ Rough/ZuumeRough-Bold.ttf') format('opentype');
}


:root {
    --color-primary: #7CF3FF; /* Customize as needed */
    --color-white: #ffffff;
    --color-background: #121728;
    --color-newBackground: #090e16;
}

.app-content {

    background: url('./assests/images/BGAmongIslamReduced.jpg') repeat;
    //background-color: #090e16;
}