// src/assets/styles/Contact.scss

.Contact-Header {
    margin-top: 40px;
    margin-right: 0px;
}

.Contact-Container {
    width: 100%;
    height: auto; /* Allow the container to adjust based on content height */
    min-height: 85vh; /* Ensure it has a minimum height */
    display: grid;
    grid-template-columns: 50% 40%; /* Adjusted column proportions */
    gap: 2rem;
    padding: 2rem;
    box-sizing: border-box;
}

.Contact-Form {
    margin-top: 150px; /* Reduced margin-top for better alignment */
    margin-bottom: 120px;
    display: flex; /* Changed to flex for better control */
    flex-direction: column;
    gap: 1.2rem;
    padding: 1rem;
    background-color: var(--color-background); /* Set background color */
    border-radius: 1rem;
    box-sizing: border-box; /* Ensures padding and borders are included in width and height */
}

input, textarea {
    width: 90%;
    padding: 1.5rem;
    margin: 5px;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: var(--color-white);
    font-size: 1rem;
    font-family: Helvetica;
}

button {
    padding: 0.4rem 2rem;
    border: none;
    border-radius: 0.5rem;  
    background-color: #7CF3FF; /* Darkened version of your primary color */
    color: var(--color-background); 
    font-size: 1.7rem;
    font-weight: 0;
    cursor: pointer;
    //font-weight: bold;
    font-family: "ZuumeRough";
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ffffff; /* Manually set a slightly darker color for hover */
    }
}

.confirmation-message {
    margin-top: 20px;
    padding: 1rem;
    background-color: var(--color-primary);
    color: var(--color-background);
    border-radius: 0.5rem;
    text-align: center;
    font-family: "Lovela";
    font-size: 1rem;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* 1024px and below */
@media (max-width: 1024px) {
    .Contact-Container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 1.5rem;
        height: 90vh;
    }

    .Contact-Form {
        padding: 1.5rem;
        margin-top: 120px;
        margin-bottom: 120px;
    }

    input, textarea {
        padding: 1.2rem;
        font-size: 0.95rem;
    }

    button {
        padding: 0.4rem 2rem;
        font-size: 1.2rem;
    }
}

/* 820px and below */
@media (max-width: 820px) {
    .Contact-Container {
        height: auto;
    }
    .Contact-Form {
        padding: 1.2rem;
    }

    input, textarea {
        padding: 1rem;
        font-size: 0.9rem;
    }

    button {
        padding: 0.3rem 1.5rem;
        font-size: 1rem;
    }

    .Contact-Header h1 {
        font-size: 3rem; /* Decrease the font size */
        padding: 0.5rem 0;
    }

    .Contact-Header h2 {
        font-size: 2rem; /* Decrease the font size */
        padding: 0.3rem 0;
    }
}

/* 600px and below */
@media (max-width: 600px) {
    .Contact-Container {
        padding: 1rem;
    }

    .Contact-Form {
        padding: 1rem;
        margin-top: 100px;
        margin-bottom: 150px;
    }

    input, textarea {
        padding: 0.9rem;
        font-size: 0.85rem;
    }

    button {
        padding: 0.2rem 1.3rem;
        font-size: 0.9rem;
    }
}

/* 400px and below */
@media (max-width: 400px) {
    .Contact-Container {
        padding: 0.8rem;
    }

    .Contact-Form {
        padding: 0.8rem;
    }

    input, textarea {
        padding: 0.7rem;
        font-size: 0.8rem;
    }

    button {
        padding: 0.4rem 1rem;
        font-size: 0.8rem;
    }
}

/* 370px and below */
@media (max-width: 375px) {
    .Contact-Container {
        padding: 0.7rem;
    }

    .Contact-Form {
        padding: 0.7rem;
    }

    input, textarea {
        padding: 0.6rem;
        font-size: 0.75rem;
    }

    button {
        padding: 0.1rem 0.9rem;
        font-size: 0.75rem;
    }
}