/* src/assets/styles/NavigationMenu.scss */

@font-face {
    font-family: 'Lovela';
    src: url('../fonts/Lovelo/Lovelo\ Black.otf') format('opentype');
}

.nav-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background);
    color: #ffffff;
    box-sizing: border-box;
    margin-top: 120px;
    margin-left: 30px;
    max-width: 50%;
    box-shadow: 0.5px 2px 5px 0px var(--color-primary);
    border-radius: 15px;

    .menu-item {
        text-align: center;
        margin-top: 40px;
        margin-left: 45px;
        margin-right: 45px;
        margin-bottom: 40px;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: inherit; /* Ensure the color is inherited */
            text-decoration: none; /* Remove underline */
            
            svg {
                font-size: 2rem;
                color: #ffffff;
                &:hover {
                    color: #7CF3FF; /* Change color on hover */
                }
            }

            p {
                margin-top: 10px;
                font-size: 1rem;
                font-family: Helvetica;
                font-weight: bold;
                &:hover {
                    color: #7CF3FF; /* Change color on hover */
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .nav-menu {
        margin-top: 50px;
        max-width: 100%;
        margin-right: 100px;
        margin-left: 100px;
        margin-bottom: 100px;
    }
}

@media (max-width: 600px) {
    .nav-menu {
        margin-top: 50px;
        max-width: 100%;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 100px;
    }

    .menu-item {
        margin: 30px !important;
    }
}