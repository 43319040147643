/* src/assets/styles/DownloadButton.scss */
.download-button {
    background-color: var(--color-primary);
    color: var(--color-background);
    border: none;
    padding: 7px 10px;
    font-size: 25px;
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #ffffff;
  }
  
  @media (max-width: 768px) {

    .download-button {
        font-size: 20px;
    }
}