/* src/assets/styles/HomeIcon.scss */
.home-icon {
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 2.5rem;
    color: #ffffff;

    a {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        color: #7CF3FF; /* Change color on hover */
    }
}


@media (max-width: 1024px) {
    home-icon {
        font-size: 2rem;
    }
}

@media (max-width: 600px) {
    .home-icon {
        top: 20px;
        right: 20px;
    }

}